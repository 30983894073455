import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useNavigate } from 'react-router-dom';
import UKFlag from '../photos/uk-flag.webp'; // Adjust the path to your flag images
import ESFlag from '../photos/es-flag.webp';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery('(max-width:499px)'); 
  const navigate = useNavigate();

  const handleChange = (event) => {
    const newLang = event.target.value;
    i18n.changeLanguage(newLang);

    navigate(`/${newLang}`);
  };

  return (
    
    <Select
      value={i18n.language}
      onChange={handleChange}
      variant="outlined"
      inputProps={{
        'aria-label': 'Select Language',
      }}
      role="combobox"
      aria-haspopup="listbox"
      sx={{
        color: '#000',
        borderRadius: '15px',  // Adjust border radius to 15px
        fontSize: '1rem',
        height: '30px',        // Set height to 30px
        backgroundColor: '#fff',
        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center'
        }
      }}
    >
      <MenuItem value="en">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={UKFlag} alt="UK Flag" style={{ width: '20px', marginRight: '8px' }} />
          English
          </Box>
          </MenuItem>
      <MenuItem value="es">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={ESFlag} alt="UK Flag" style={{ width: '20px', marginRight: '8px' }} />
         Spanish
         </Box>
         </MenuItem>
    </Select>
  );
};

export default LanguageSelector;
