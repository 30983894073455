import React, { useEffect, useState } from 'react'; // Added useState
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HeroEN from '../photos/hero-en.webp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';

const HeroSection = ({ onLoaded }) => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const isXs = useMediaQuery('(max-width:900px)');
  const [imageLoaded, setImageLoaded] = useState(false); // Track if the image has loaded

  // Preload the dashboard image
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = HeroEN;
    link.as = 'image';
    link.fetchPriority = 'high';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link); // Cleanup when component unmounts
    };
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true); // Update state when the image is loaded
    onLoaded(); // Notify parent component (App.js) when Hero Section has loaded
  };

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang); // Change language based on URL
    }
  }, [lang, i18n]);

  const registerUrl = `https://www.citasonic.com/${i18n.language}/register`;
  const loginUrl = `https://www.citasonic.com/${i18n.language}/login`;

  return (
    <Box 
      component="section"
      sx={{ 
        position: 'relative',
        backgroundImage: 'linear-gradient(to right, #0a1425, #2c3e50)', 
        color: '#fff', 
        minHeight: '85vh', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center',
        textAlign: 'center',
        overflow: 'hidden'
      }}
    >
      <Box sx={{ maxWidth: 1200, px: 2, mx: 'auto', zIndex: 2 }}>
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={4}>
          {/* Image Section */}
          <Grid item xs={12} sm={8}>
            <Box 
              component="img"
              src={HeroEN}
              alt="Dashboard"
              loading="eager"
              fetchPriority="high"
              onLoad={handleImageLoad} // Call handleImageLoad when image loads
              sx={{ 
                width: '100%', 
                maxWidth: {
                  xs: '80%', // Full width for mobile
                  sm: '500px', // Smaller width for small screens
                  md: '600px', // Original width for medium screens and up
                },
                marginTop: {
                  xs: '80px',
                },
                opacity: 1,
                zIndex: 1,
                borderRadius: '10px',
                aspectRatio: '600 / 253',
                maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))', // Fades from 100% opacity to 0%
                WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))', // WebKit support
              }}
            />
          </Grid>

          {/* Text Content */}
          <Grid item xs={12} sm={10}>
            <Typography 
              variant="h1" 
              component="h1" 
              gutterBottom 
              sx={{ fontSize: { xs: '2.5rem', sm: '3rem' }, fontWeight: '700', mb: 3, minHeight: '3rem' }}
            >
              {t('Home.title')}
            </Typography>
            <Typography 
              variant="h2" 
              component="h2" 
              sx={{ 
                mb: 4, 
                fontSize: { xs: '1.2rem', sm: '1.8rem' }, 
                fontWeight: '700', 
                background: 'linear-gradient(90deg, #FF8C00 0%, #FFD700 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                minHeight: '4rem'
              }}
            >
              {t('Home.subtitle')}
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                mb: 4, 
                fontSize: { xs: '1rem', sm: '1.2rem' }, 
                maxWidth: { xs: '100%', sm: '800px' }, 
                mx: 'auto', 
                minHeight: '3rem'
              }}
            >
              {t('Home.description')}
            </Typography>
          </Grid>

          {/* Buttons */}
          <Grid item container spacing={2} justifyContent="center" paddingBottom="30px">
            <Grid item>
              <Button 
                variant="contained" 
                href={registerUrl}
                sx={{ 
                  padding: '10px 25px', 
                  borderRadius: '25px', 
                  backgroundColor: '#3877ce', 
                  color: '#fff', 
                  fontWeight: 'bold', 
                  fontSize: '1rem', 
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#245fb1', 
                  },
                  '&:focus': {
                    backgroundColor: '#245fb1', 
                  }
                }}
              >
                {t('Home.tryForFree')}
              </Button>
            </Grid>
            <Grid item>
              <Button 
                href={loginUrl}
                sx={{ 
                  padding: '10px 25px', 
                  backgroundColor: 'transparent', 
                  color: '#fff', 
                  fontWeight: 'bold', 
                  fontSize: '1rem', 
                  boxShadow: 'none',
                  borderColor: '#FFF',
                  '&:hover': {
                    color: '#fff', // Change text color on hover if needed
                    border: '2px solid'
                  },
                  '&:focus': {
                    color: '#fff', // Change text color on focus if needed
                    border: '2px solid'
                  }
                }}
                endIcon={<ArrowForwardIcon />}
              >
                {t('Home.login')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HeroSection;
