// serviceWorkerRegistration.js

// Register the service worker for production environments
export function register() {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/serviceWorker.js')
          .then((registration) => {
            console.log('Service worker registered with scope:', registration.scope);
          })
          .catch((error) => {
            console.error('Service worker registration failed:', error);
          });
      });
    }
  }
  
  // Unregister the service worker if needed
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        });
    }
  }
  