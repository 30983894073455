import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import logo from '../logo.webp';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
      button: {
        fontWeight: 600,
      },
      
  },
});

function Navbar({ onLoad }) {
  const [scroll, setScroll] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:499px)');
  const { t, i18n } = useTranslation();

  
  useEffect(() => {
    // Call onLoad once the component has mounted
    if (onLoad) {
      onLoad();
    }
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setScroll(1); // Fully dark and fully shadowed
      } else if (scrollTop < 5) {
        setScroll(0); // Fully transparent and no shadow
      } else {
        setScroll((scrollTop - 5) / 45); // Interpolated value
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onLoad]); // Add onLoad to the dependency array


  const backgroundColor = `rgba(10, 20, 37, ${scroll})`;
  const boxShadow = `0px 4px 12px rgba(0, 0, 0, ${scroll * 0.1})`;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const registerUrl = `https://www.citasonic.com/${i18n.language}/register`;
  const loginUrl = `https://www.citasonic.com/${i18n.language}/login`;

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed" sx={{ backgroundColor, boxShadow, transition: 'all 0.3s' }}>
        <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        <Link to="/">
            <img
              src={logo} // Update this path to your actual logo
              alt="Logo"
              style={{ height: 30 }} // Adjust size as needed
            />
            </Link>
          </Box>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            minWidth: '150px',
            ...(isMobile && {       // Apply these styles only when isMobile is true
              position: 'absolute',  // Position absolute when mobile
              top: '90vh',        // Position 10px from bottom
              right: '10px',         // Position 10px from right
              zIndex: 1000,          // Ensure it floats above other elements
              padding: '5px 0px',
              borderRadius: '25px',
              backgroundColor: '#fff',
              border: '5px solid',
              justifyContent: 'center',
            }),
          }}
        >
              <LanguageSelector />
            </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            <Button color="inherit" href="#price" sx={{ marginLeft: 1, textTransform: 'none' }}>
            {t('navbar.price')}
            </Button>
            <Divider orientation="vertical" flexItem sx={{ marginLeft: 2, marginRight: 2, backgroundColor: 'white' }} />
            <Button color="inherit" href={loginUrl} sx={{ marginLeft: 1, textTransform: 'none' }}>
            {t('navbar.login')}
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <Button
  variant="contained"
  href={registerUrl}
  sx={{
    background: 'linear-gradient(90deg, #FF8C00 0%, #FFD700 100%)',
    color: '#fff', // Set text color to white
    textTransform: 'none',
    display: { xs: 'inline-flex', md: 'inline-flex' },
    padding: '8px 16px', // Adjust padding as needed
    '&:hover': {
      background: 'linear-gradient(90deg, #FF8C00 0%, #FFD700 100%)', // Keep the gradient on hover
      opacity: 0.9, // Optional: Add slight opacity on hover for effect
    }
  }}
>
{t('navbar.freeTrial')}
</Button>
            <IconButton color="inherit" edge="end" onClick={handleDrawerToggle} sx={{ display: { xs: 'inline-flex', md: 'none' }, marginLeft: 1 }} aria-label="Open Menu">
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <List sx={{ width: 250 }}>
          <ListItem button component="a" href="#price">
            <ListItemText primary={t('navbar.price')} />
          </ListItem>
          <Divider />
          <ListItem button component="a" href={loginUrl}>
            <ListItemText primary={t('navbar.login')} />
          </ListItem>
        </List>
      </Drawer>
    </ThemeProvider>
  );
}

export default Navbar;
