import React, { useEffect, Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next'; // Import I18nextProvider
import './App.css'; // Import global styles
import Navbar from './components/Navbar'; // Import the Navbar component
import Home from './pages/Home'; // Import Home page
import { Helmet } from 'react-helmet';

const NotFound = React.lazy(() => import('./pages/NotFound'));

const DefaultRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to /en if no language is specified in the URL
    const lang = window.location.pathname.split('/')[1];
    if (!lang || (lang !== 'en' && lang !== 'es')) {
      navigate('/en');
    }
  }, [navigate]);

  return null; // This component doesn't render anything
};

function App() {
  const { i18n } = useTranslation(); // Get the current language using i18n
  const lang = i18n.language; // The current language (e.g., 'en' or 'es')
  const baseUrl = 'https://www.citasonic.com'; // Base URL of your site

 // Track loading states for Navbar and HeroSection
 const [isNavbarLoaded, setIsNavbarLoaded] = useState(false);
 const [isHeroLoaded, setIsHeroLoaded] = useState(false); 

  // Add PerformanceObserver to measure when the components load
  useEffect(() => {
    const performanceObserver = new PerformanceObserver((list) => {
      for (const entry of list.getEntries()) {
        // Here, you can send performance data to your server or log it
        console.log('Performance Entry:', entry);
      }
    });
    performanceObserver.observe({ type: 'paint', buffered: true });

    // Cleanup the observer on unmount
    return () => {
      performanceObserver.disconnect();
    };
  }, []);

  // Add DOMContentLoaded Event to check when critical components are loaded
  useEffect(() => {
    const handleLoad = () => {
      if (isNavbarLoaded && isHeroLoaded) {
        // This will be called when both components are loaded
        console.log('DOMContentLoaded: Page is ready with critical content.');
        
        // Optionally mark performance
        performance.mark('DOMContentLoadedComplete');
        performance.measure('DOMContentLoaded to Fully Loaded', 'DOMContentLoadedComplete', 'criticalContentLoaded');
      }
    };

    window.addEventListener('DOMContentLoaded', handleLoad);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('DOMContentLoaded', handleLoad);
    };
  }, [isNavbarLoaded, isHeroLoaded]);


   // Add Google Analytics in useEffect
   useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-QE64TCCHDY`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      try {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-QE64TCCHDY');
      } catch (error) {
        console.error('Google Analytics tracking failed', error);
      }
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}> {/* Wrap with I18nextProvider */}
      <Helmet>
        {/* Dynamic hreflang tags based on the current language */}
        <link rel="alternate" href={`${baseUrl}/en`} hreflang="en" />
        <link rel="alternate" href={`${baseUrl}/es`} hreflang="es" />
        <link rel="alternate" href={baseUrl} hreflang="x-default" />
      </Helmet>
      <Router>
        <div className="App">
        {/* Render Navbar first and set loading states */}
        <Navbar onLoad={() => setIsNavbarLoaded(true)} /> {/* Pass onLoad to Navbar */}
          
          <main className="Main">
              <Routes>
                <Route path="/" element={<DefaultRedirect />} /> 
                <Route 
                path="/:lang" 
                element={
                  <Home 
                    onLoaded={() => setIsHeroLoaded(true)} // Callback for HeroSection loading
                  /> 
                } 
              /> 
                <Route path="*" element={<Suspense fallback={<div>Loading...</div>}><NotFound /></Suspense>} />
              </Routes>
          </main>
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;
