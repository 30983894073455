import React, { useRef, useEffect, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useParams, useLocation } from 'react-router-dom';
import HeroSection from '../components/HeroSection';
import HeroEN from '../photos/hero-en.webp';

// Lazy load components
const ManageAppointmentsSection = React.lazy(() => import('../components/ManageAppointmentsSection'));
const ProfileSection = React.lazy(() => import('../components/ProfileSection'));
const Price = React.lazy(() => import('../components/Price'));
const PriceMobile = React.lazy(() => import('../components/PriceMobile'));
const FAQ = React.lazy(() => import('../components/FAQ'));
const BannerMobile = React.lazy(() => import('../components/BannerMobile'));
const CaseStudy = React.lazy(() => import('../components/CaseStudy'));




const Home = () => {
  const { t, i18n } = useTranslation();
  const { lang } = useParams(); 
  const priceRef = useRef(null);
  const location = useLocation();
  const isXs = useMediaQuery('(max-width:900px)');

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang); // Change language based on URL
    }
  }, [lang, i18n]);

   // Handle when HeroSection has loaded
   const handleHeroLoaded = () => {
    console.log("HeroSection has loaded.");
    // You can add any other actions you want to perform when the HeroSection loads.
  };

    useEffect(() => {

      if (location.hash === '#price' && priceRef.current) {
        const element = priceRef.current;
        const scrollToElement = (element, duration) => {
          const start = window.pageYOffset;
          const end = element.getBoundingClientRect().top + window.pageYOffset;
          const distance = end - start;
          let startTime = null;
  
          const animateScroll = (currentTime) => {
            if (startTime === null) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const run = easeInOutQuad(timeElapsed, start, distance, duration);
            window.scrollTo(0, run);
            if (timeElapsed < duration) requestAnimationFrame(animateScroll);
          };
  
          const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
          };
  
          requestAnimationFrame(animateScroll);
        };
  
        scrollToElement(element, 1000); // Change 1000 to the desired duration in milliseconds
      }
    }, [location]);
 
    
  const registerUrl = `https://www.citasonic.com/${i18n.language}/register`;
  const loginUrl = `https://www.citasonic.com/${i18n.language}/login`;

  return (
   <div>
     <Box component="section">
        <HeroSection onLoaded={handleHeroLoaded} /> {/* Pass the onLoaded function */}
      </Box>

{/* Lazy Loaded Sections */}
<Suspense fallback={<div>Loading sections...</div>}>
        {/* Manage Appointments Section */}
<Box 
  component="section"
  sx={{
    backgroundColor: '#f9f9f9', 
    color: '#000',
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    marginTop: '60px',
    marginBottom: '60px'
  }}
>
<ManageAppointmentsSection />

</Box>
{/* Features Section */}
<Box 
  component="section"
  sx={{ 
    backgroundColor: '#f9f9f9', 
    color: '#000', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    marginBottom: '60px'
  }}
>
<ProfileSection />

</Box>
     
<Box 
  component="section"
  sx={{ 
    backgroundColor: '#f9f9f9', 
    color: '#000',
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    marginBottom: '60px'
  }}
>
<CaseStudy />

</Box>
  
      {/* Banner Section */}
      <Box 
  component="section"
  sx={{ 
    backgroundColor: '#f9f9f9', 
    color: '#000',
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    padding: '10px',
  }}
>
 <BannerMobile /> 

</Box>
      <Box 
        component="section"
        ref={priceRef}
        id="price"
        sx={{
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center',
          backgroundColor: '#f4f4f9',
          width: '100%'
        }}
      >
        <Grid item xs={12} md={4}>
          <Box>
          {isXs ? <PriceMobile /> : <Price />}
          </Box>
        </Grid>
      </Box>
      {/* FAQ Section */}
<Box 
  component="section"
  sx={{ 
    color: '#000', 
    padding: 4, 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center'
  }}
>
<FAQ />

</Box>

      {/* Call to Action Section */}
      <Box 
        component="section"
        sx={{ 
          backgroundImage: 'linear-gradient(to right, #ff7043, #ff4081)', 
          color: '#fff', 
          padding: 4, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Typography variant="h3" component="h3" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem' }, fontWeight: '600', mb: 4 }}>
        {t('Home.ready_to_get_started')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, fontSize: { xs: '1rem', sm: '1.2rem' }, maxWidth: { xs: '100%', sm: '800px' }, mx: 'auto' }}>
        {t('Home.sign_up_today')}
        </Typography>
        <Button variant="contained"
        href={registerUrl} sx={{ 
          padding: '10px 25px', 
          backgroundColor: '#fff', 
          color: '#ff7043', 
          fontWeight: 'bold', 
          fontSize: '1rem' ,
          '&:hover': {
            color: '#fff',
            backgroundColor: '#245fb1', 
          },
          '&:focus': {
            color: '#fff',
            backgroundColor: '#245fb1', 
          }
        }}
        >
          {t('Home.start_free_trial')}
        </Button>
      </Box>

      {/* Footer */}
      <Box 
        component="footer"
        sx={{ 
          backgroundColor: '#2c3e50', 
          color: '#fff', 
          padding: 4, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Typography variant="body1" sx={{ mb: 2 }}>
          &copy; 2024 Citasonic. All rights reserved.
        </Typography>
       
      </Box>
      </Suspense>
    </div>
  );
}

export default Home;
